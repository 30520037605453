<template>
  <div>
    <!-- <div v-show="tabState < 3">
      <OnboardingLayout>
        <b-row
          class="d-flex flex-row align-items-center justify-content-end w-100 mt-4"
        >
          <h5
            class="font-primary font-weight-normal text-main-green text-uppercase text-center mr-4"
          >
            Step {{ tabState + 1 }} OF 3
          </h5>
        </b-row>
        <b-card
          no-body
          class="d-flex flex-column align-items-center justify-content-center bg-transparent border-0 rounded-0 px-1 py-3 login-container-card"
          :style="
            tabState === 3
              ? 'max-width:800px;margin-left:50px'
              : 'max-width: 600px'
          "
        >

          <b-row
            class="d-flex flex-row align-items-stretch justify-content-center w-100"
          >
          
            <b-tabs v-model="tabState" nav-class="root-tabs" class="w-100">
              <b-tab>
                <BusinessDetailsTab />
              </b-tab>
              <b-tab>
                <RegionalDetailsTab />
              </b-tab>
              <b-tab>
                <AdditionalDetailsTab />
              </b-tab>
            </b-tabs>
          </b-row>
        </b-card>
      </OnboardingLayout>
    </div> -->
    <PackageDetailsTab />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// services
//   import { LoginNew } from "../services/login.service";

// components
// @ is an alias to /src
// import OnboardingLayout from "@/layout/OnboardingLayout";
// import FormInput from "@/components/Form/FormInput";
// import FormSelect from "@/components/Form/FormSelect";
// import BusinessDetailsTab from "@/components/Onboarding/BusinessDetailsTab";
// import RegionalDetailsTab from "@/components/Onboarding/RegionalDetailsTab";
// import AdditionalDetailsTab from "@/components/Onboarding/AdditionalDetailsTab";
import PackageDetailsTab from "@/components/Onboarding/PackageDetailsTab";

export default {
  name: "Login",
  components: {
    // OnboardingLayout,
    // FormInput,
    // FormSelect,
    // BusinessDetailsTab,
    // RegionalDetailsTab,
    // AdditionalDetailsTab,
    PackageDetailsTab,
  },
  data() {
    return {
      isLoader: false,
      // tabState: 0,
    };
  },
  watch: {
    tabState(val) {
      console.log(val);
    },
  },
  computed: {
    ...mapGetters({
      getTabState: "general/getTabState",
    }),
    tabState: {
      get() {
        return this.getTabState;
      },
      // set(val) {
      //   this.$store.dispatch("general/setTabState", val);
      // },
    },
  },
  mounted() {
    this.$route.query
    console.log("🚀 ~ file: Onboarding.vue:101 ~ mounted ~ this.$route.query:", this.$route.query)
  },
  // methods: {
  //   nextFn() {
  //     this.tabState++;
  //   },
  // },
};
</script>

<style scoped>
.login-container-card {
  width: 80%;
  min-width: 300px;
  min-height: 300px;
  max-width: 600px;
  margin-right: 200px;
}

.login-container-card >>> .root-tabs {
  display: none;
}

.login-container-card .register-button {
  opacity: 1;
  transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
    color 200ms ease-in;
}

.login-container-card .register-button:hover {
  opacity: 0.7;
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.15) !important;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .login-container-card {
    min-height: fit-content;
    margin-right: 0;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .login-container-card {
    width: 90%;
    margin-right: 0;
  }
}
</style>
